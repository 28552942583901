import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/travis/build/reach/reach-ui/website/src/components/mdx-layout.js";
import SEO from "../components/SEO";
import { TOC, TOCList, TOCLink } from "../components/TOC";
import { AsPropWarning } from "../components/AsPropWarning";
import { Pipe } from "../components/Pipe";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <SEO title="Menu Button" description="An accessible component for the common dropdown menu button design pattern in React" mdxType="SEO" />
    <h1 {...{
      "id": "menu-button"
    }}>{`Menu Button`}</h1>
    <TOC mdxType="TOC">
  <TOCList mdxType="TOCList">
    <TOCLink href="#menu" mdxType="TOCLink">Menu</TOCLink>
    <TOCLink href="#menubutton" mdxType="TOCLink">MenuButton</TOCLink>
    <TOCLink href="#menulist" mdxType="TOCLink">MenuList</TOCLink>
    <TOCLink href="#menupopover" mdxType="TOCLink">MenuPopover</TOCLink>
    <TOCLink href="#menuitems" mdxType="TOCLink">MenuItems</TOCLink>
    <TOCLink href="#menuitem" mdxType="TOCLink">MenuItem</TOCLink>
    <TOCLink href="#menulink" mdxType="TOCLink">MenuLink</TOCLink>
    <TOCLink href="#usemenubuttoncontext" mdxType="TOCLink">useMenuButtonContext</TOCLink>
  </TOCList>
    </TOC>
    <ul>
      <li parentName="ul">{`Source: `}<a parentName="li" {...{
          "href": "https://github.com/reach/reach-ui/tree/main/packages/menu-button"
        }}>{`https://github.com/reach/reach-ui/tree/main/packages/menu-button`}</a></li>
      <li parentName="ul">{`WAI-ARIA: `}<a parentName="li" {...{
          "href": "https://www.w3.org/TR/wai-aria-practices-1.2/#menubutton"
        }}>{`https://www.w3.org/TR/wai-aria-practices-1.2/#menubutton`}</a></li>
    </ul>
    <p>{`An accessible dropdown menu for the common dropdown menu button design pattern.`}</p>
    <p>{`Please note that the buttons on this page are styled by this website. They are just buttons, so they will appear the same as any other button in your app.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`// jsx-demo
function Example() {
  return (
    <Menu>
      <MenuButton>
        Actions <span aria-hidden>▾</span>
      </MenuButton>
      <MenuList>
        <MenuItem onSelect={() => alert("Download")}>Download</MenuItem>
        <MenuItem onSelect={() => alert("Copy")}>Create a Copy</MenuItem>
        <MenuItem onSelect={() => alert("Mark as Draft")}>
          Mark as Draft
        </MenuItem>
        <MenuItem onSelect={() => alert("Delete")}>Delete</MenuItem>
        <MenuLink as="a" href="https://reacttraining.com/workshops/">
          Attend a Workshop
        </MenuLink>
      </MenuList>
    </Menu>
  );
}
`}</code></pre>
    <h2 {...{
      "id": "installation"
    }}>{`Installation`}</h2>
    <p>{`From the command line in your project directory, run `}<inlineCode parentName="p">{`npm install @reach/menu-button`}</inlineCode>{` or `}<inlineCode parentName="p">{`yarn add @reach/menu-button`}</inlineCode>{`. Then import the components and styles that you need:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`npm install @reach/menu-button
# or
yarn add @reach/menu-button
`}</code></pre>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`import {
  Menu,
  MenuList,
  MenuButton,
  MenuItem,
  MenuItems,
  MenuPopover,
  MenuLink,
} from "@reach/menu-button";
import "@reach/menu-button/styles.css";
`}</code></pre>
    <h2 {...{
      "id": "component-api"
    }}>{`Component API`}</h2>
    <h3 {...{
      "id": "menu"
    }}>{`Menu`}</h3>
    <p>{`The wrapper component for the other components. No DOM element is rendered.`}</p>
    <h4 {...{
      "id": "menu-props"
    }}>{`Menu Props`}</h4>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Prop`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Type`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Required`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "#menu-children"
            }}><inlineCode parentName="a">{`children`}</inlineCode></a></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`node`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`false`}</td>
        </tr>
      </tbody>
    </table>
    <h5 {...{
      "id": "menu-children"
    }}>{`Menu children`}</h5>
    <p><inlineCode parentName="p">{`children: React.ReactNode | ((props: { isExpanded: boolean }) => React.ReactNode)`}</inlineCode></p>
    <p>{`Requires two children: a `}<inlineCode parentName="p">{`MenuButton`}</inlineCode>{` and a `}<inlineCode parentName="p">{`MenuList`}</inlineCode>{`.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`// jsx-demo
function Example() {
  return (
    <Menu>
      <MenuButton>Actions</MenuButton>
      <MenuList>
        <MenuItem>Download</MenuItem>
        <MenuLink to="view">View</MenuLink>
      </MenuList>
    </Menu>
  );
}
`}</code></pre>
    <p>{`Alternatively, you can provide a render callback. This is helpful if you need to access the internal state of the Menu.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`// jsx-demo
function Example() {
  return (
    <Menu>
      {({ isExpanded }) => (
        <React.Fragment>
          <MenuButton>
            {isExpanded ? "Close" : "Open"} <span aria-hidden="true">▾</span>
          </MenuButton>
          <MenuList>
            <MenuItem>Download</MenuItem>
            <MenuItem>Create a Copy</MenuItem>
          </MenuList>
        </React.Fragment>
      )}
    </Menu>
  );
}
`}</code></pre>
    <h3 {...{
      "id": "menubutton"
    }}>{`MenuButton`}</h3>
    <p>{`Wraps a DOM `}<inlineCode parentName="p">{`button`}</inlineCode>{` that toggles the opening and closing of the dropdown menu. Must be rendered inside of a `}<inlineCode parentName="p">{`<Menu>`}</inlineCode>{`.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`<Menu>
  <MenuButton>Profile</MenuButton>
  {/* ... */}
</Menu>
`}</code></pre>
    <h4 {...{
      "id": "menubutton-css-selectors"
    }}>{`MenuButton CSS Selectors`}</h4>
    <p>{`Please see the `}<a parentName="p" {...{
        "href": "/styling"
      }}>{`styling guide`}</a>{`.`}</p>
    <p>{`A `}<inlineCode parentName="p">{`<MenuButton>`}</inlineCode>{` wraps a normal `}<inlineCode parentName="p">{`<button>`}</inlineCode>{` and no styles are applied to it, so any global button styles you have will be applied.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-css"
      }}>{`button {
  /* your normal button styles will be applied */
}
`}</code></pre>
    <p>{`You can use the `}<inlineCode parentName="p">{`[data-reach-menu-button]`}</inlineCode>{` selector to style only the dropdown buttons:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-css"
      }}>{`[data-reach-menu-button] {
  color: blue;
}
`}</code></pre>
    <p>{`If you'd like to target when the menu is open use `}<inlineCode parentName="p">{`aria-expanded`}</inlineCode>{`:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-css"
      }}>{`[data-reach-menu-button][aria-expanded="true"] {
  background: #000;
  color: white;
}
`}</code></pre>
    <h4 {...{
      "id": "menubutton-props"
    }}>{`MenuButton Props`}</h4>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Prop`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Type`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Required`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "#menubutton-button-props"
            }}><inlineCode parentName="a">{`button`}</inlineCode>{` props`}</a></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "#menubutton-children"
            }}><inlineCode parentName="a">{`children`}</inlineCode></a></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`node`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`false`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`onMouseDown`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`preventableEventFunc`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`false`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`onKeyDown`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`preventableEventFunc`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`false`}</td>
        </tr>
      </tbody>
    </table>
    <h5 {...{
      "id": "menubutton-button-props"
    }}>{`MenuButton button props`}</h5>
    <p>{`Any props not listed above will be spread onto the underlying button element. You can treat it like any other button in your app for styling.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`<Menu>
  <MenuButton
    className="button-primary"
    style={{ boxShadow: "2px 2px 2px hsla(0, 0%, 0%, 0.25)" }}
  >
    Actions <span aria-hidden>▾</span>
  </MenuButton>
  <MenuList>
    <MenuItem onSelect={() => {}}>Do nothing</MenuItem>
  </MenuList>
</Menu>
`}</code></pre>
    <h5 {...{
      "id": "menubutton-as"
    }}>{`MenuButton as`}</h5>
    <p><inlineCode parentName="p">{`as?: keyof JSX.IntrinsicElements | React.ComponentType`}</inlineCode></p>
    <p>{`A string representing an HTML element or a React component that will tell the `}<inlineCode parentName="p">{`MenuButton`}</inlineCode>{` what underlying element to render. Defaults to `}<inlineCode parentName="p">{`button`}</inlineCode>{`.`}</p>
    <AsPropWarning mdxType="AsPropWarning" />
    <h5 {...{
      "id": "menubutton-children"
    }}>{`MenuButton children`}</h5>
    <p><inlineCode parentName="p">{`children: React.ReactNode`}</inlineCode></p>
    <p>{`Accepts any renderable content.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`<MenuButton>
  Actions{" "}
  <span aria-hidden>
    <Gear />
  </span>
</MenuButton>
`}</code></pre>
    <h3 {...{
      "id": "menulist"
    }}>{`MenuList`}</h3>
    <p>{`Wraps a DOM element that renders the menu items. Must be rendered inside of a `}<inlineCode parentName="p">{`<Menu>`}</inlineCode>{`.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`<Menu>
  {/* ... */}
  <MenuList>
    <MenuItem onSelect={() => {}}>Download</MenuItem>
  </MenuList>
</Menu>
`}</code></pre>
    <h4 {...{
      "id": "menulist-css-selectors"
    }}>{`MenuList CSS Selectors`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-css"
      }}>{`[data-reach-menu-list] {
  padding: 20px 10px;
}
`}</code></pre>
    <h4 {...{
      "id": "menulist-props"
    }}>{`MenuList Props`}</h4>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Prop`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Type`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Required`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "#menuitems-div-props"
            }}><inlineCode parentName="a">{`div`}</inlineCode>{` props`}</a></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "#menuitems-children"
            }}><inlineCode parentName="a">{`children`}</inlineCode></a></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`node`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`false`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "#menuitems-portal"
            }}><inlineCode parentName="a">{`portal`}</inlineCode></a></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`boolean`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`false`}</td>
        </tr>
      </tbody>
    </table>
    <h5 {...{
      "id": "menulist-div-props"
    }}>{`MenuList div props`}</h5>
    <p>{`All props are spread to the underlying element. Here we apply a `}<inlineCode parentName="p">{`className`}</inlineCode>{` the element.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`// jsx-demo
function Example() {
  return (
    <Menu>
      <MenuButton>
        Actions <span aria-hidden>▾</span>
      </MenuButton>
      <MenuList className="slide-down">
        <MenuItem onSelect={() => {}}>Start Video</MenuItem>
        <MenuItem onSelect={() => {}}>Start Screenshare</MenuItem>
        <MenuItem onSelect={() => {}}>Send a Message</MenuItem>
      </MenuList>
    </Menu>
  );
}
`}</code></pre>
    <p>{`The stylesheet contains these rules to create the animation.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-css"
      }}>{`@keyframes slide-down {
  0% {
    opacity: 0;
    transform: translateY(-10px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.slide-down[data-reach-menu-list],
.slide-down[data-reach-menu-items] {
  border-radius: 5px;
  animation: slide-down 0.2s ease;
}
`}</code></pre>
    <h5 {...{
      "id": "menulist-children"
    }}>{`MenuList children`}</h5>
    <p><inlineCode parentName="p">{`children: React.ReactNode`}</inlineCode></p>
    <p>{`Can contain only `}<inlineCode parentName="p">{`MenuItem`}</inlineCode>{` or a `}<inlineCode parentName="p">{`MenuLink`}</inlineCode></p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`<MenuList>
  <MenuItem />
  <MenuLink />
</MenuList>
`}</code></pre>
    <h5 {...{
      "id": "menulist-portal"
    }}>{`MenuList portal`}</h5>
    <p><inlineCode parentName="p">{`portal?: boolean`}</inlineCode></p>
    <p>{`Whether or not the popover should be rendered inside a portal. Defaults to `}<inlineCode parentName="p">{`true`}</inlineCode>{`.`}</p>
    <h3 {...{
      "id": "menupopover"
    }}>{`MenuPopover`}</h3>
    <p>{`A low-level wrapper for the popover that appears when a menu button is open. You can compose it with `}<inlineCode parentName="p">{`MenuItems`}</inlineCode>{` for more control over the nested components and their rendered DOM nodes, or if you need to nest arbitrary components between the outer wrapper and your list.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`<Menu>
  {/* ... */}
  <MenuPopover>
    <div className="arbitrary-element">
      <MenuItems>
        <MenuItem onSelect={() => {}}>Download</MenuItem>
      </MenuItems>
    </div>
  </MenuPopover>
</Menu>
`}</code></pre>
    <h4 {...{
      "id": "menupopover-css-selectors"
    }}>{`MenuPopover CSS Selectors`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-css"
      }}>{`[data-reach-menu-popover] {
}
`}</code></pre>
    <h4 {...{
      "id": "menupopover-props"
    }}>{`MenuPopover Props`}</h4>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Prop`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Type`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Required`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "#menupopover-children"
            }}><inlineCode parentName="a">{`children`}</inlineCode></a></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`node`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`true`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "#menupopover-portal"
            }}><inlineCode parentName="a">{`portal`}</inlineCode></a></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`boolean`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`false`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "#menupopover-position"
            }}><inlineCode parentName="a">{`position`}</inlineCode></a></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`func`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`false`}</td>
        </tr>
      </tbody>
    </table>
    <h5 {...{
      "id": "menupopover-children-props"
    }}>{`MenuPopover children props`}</h5>
    <p><inlineCode parentName="p">{`children: React.ReactNode`}</inlineCode></p>
    <h5 {...{
      "id": "menupopover-portal"
    }}>{`MenuPopover portal`}</h5>
    <p><inlineCode parentName="p">{`portal?: boolean`}</inlineCode></p>
    <p>{`Whether or not the popover should be rendered inside a portal. Defaults to `}<inlineCode parentName="p">{`true`}</inlineCode>{`.`}</p>
    <h5 {...{
      "id": "menupopover-position"
    }}>{`MenuPopover position`}</h5>
    <p><inlineCode parentName="p">{`position?: (targetRect?: DOMRect | null, popoverRect?: DOMRect | null) => React.CSSProperties`}</inlineCode></p>
    <p>{`A function used to determine the position of the popover in relation to the menu button. By default, the menu button will attempt to position the popover below the button aligned with its left edge. If this positioning results in collisions with any side of the window, the popover will be anchored to a different side to avoid those collisions if possible.`}</p>
    <p>{`The function accepts the DOMRect of the menu button element, and the DOMRect of the popover element as arguments, and returns a CSS properties object that is then applied to the popover as inline styles.`}</p>
    <h3 {...{
      "id": "menuitems"
    }}>{`MenuItems`}</h3>
    <p>{`A low-level wrapper for menu items. Compose it with `}<inlineCode parentName="p">{`MenuPopover`}</inlineCode>{` for more control over the nested components and their rendered DOM nodes, or if you need to nest arbitrary components between the outer wrapper and your list.`}</p>
    <p>{`See `}<a parentName="p" {...{
        "href": "#menupopover"
      }}><inlineCode parentName="a">{`MenuPopover`}</inlineCode></a>{` for details.`}</p>
    <h4 {...{
      "id": "menuitems-css-selectors"
    }}>{`MenuItems CSS Selectors`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-css"
      }}>{`[data-reach-menu-items] {
}
`}</code></pre>
    <h3 {...{
      "id": "menuitem"
    }}>{`MenuItem`}</h3>
    <p>{`Handles menu selection. Must be a direct child of a `}<inlineCode parentName="p">{`<MenuList>`}</inlineCode>{`.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`<MenuList>
  <MenuItem onSelect={() => alert("download!")}>Download</MenuItem>
</MenuList>
`}</code></pre>
    <h4 {...{
      "id": "menuitem-css-selectors"
    }}>{`MenuItem CSS Selectors`}</h4>
    <p>{`Please see the `}<a parentName="p" {...{
        "href": "/styling"
      }}>{`styling guide`}</a>{`.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-css"
      }}>{`[data-reach-menu-item] {
  padding: 20px 10px;
}
`}</code></pre>
    <p>{`To change the styles of a highlighted menu item, use this pseudo-pseudo selector:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-css"
      }}>{`[data-reach-menu-item][data-selected] {
  background: red;
}
`}</code></pre>
    <p>{`The following example has this css applied:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-css"
      }}>{`.red-highlight[data-reach-menu-item][data-selected] {
  background: red;
}
`}</code></pre>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`// jsx-demo
function Example() {
  return (
    <Menu>
      <MenuButton>
        Actions <span aria-hidden>▾</span>
      </MenuButton>
      <MenuList>
        <MenuItem className="red-highlight" onSelect={() => {}}>
          Start Video
        </MenuItem>
        <MenuItem className="red-highlight" onSelect={() => {}}>
          Start Screenshare
        </MenuItem>
      </MenuList>
    </Menu>
  );
}
`}</code></pre>
    <h4 {...{
      "id": "menuitem-props"
    }}>{`MenuItem Props`}</h4>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Prop`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Type`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Required`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "#menuitem-element-props"
            }}>{`element props`}</a></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "#menuitem-as"
            }}><inlineCode parentName="a">{`as`}</inlineCode></a></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`string`}</inlineCode>{` `}<Pipe mdxType="Pipe" />{` `}<inlineCode parentName="td">{`Component`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`false`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "#menuitem-children"
            }}><inlineCode parentName="a">{`children`}</inlineCode></a></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`node`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`false`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "#menuitem-disabled"
            }}><inlineCode parentName="a">{`disabled`}</inlineCode></a></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`boolean`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`false`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "#menuitem-onselect"
            }}><inlineCode parentName="a">{`onSelect`}</inlineCode></a></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`func`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`true`}</td>
        </tr>
      </tbody>
    </table>
    <h5 {...{
      "id": "menuitem-element-props"
    }}>{`MenuItem element props`}</h5>
    <p>{`All props are spread to the underlying element.`}</p>
    <p>{`In this example the `}<inlineCode parentName="p">{`onFocus`}</inlineCode>{` prop is passed down to the element.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`// jsx-demo
function Example(props) {
  const [focusCount, setFocusCount] = React.useState(0);
  return (
    <Menu>
      <MenuButton>Actions</MenuButton>
      <MenuList>
        <MenuItem
          onFocus={() => {
            setFocusCount((prevFocusCount) => prevFocusCount + 1);
          }}
          onSelect={() => {}}
        >
          Focused {focusCount} Times
        </MenuItem>
        <MenuItem onSelect={() => {}}>Start Screenshare</MenuItem>
        <MenuItem onSelect={() => {}}>Send a Message</MenuItem>
      </MenuList>
    </Menu>
  );
}
`}</code></pre>
    <h5 {...{
      "id": "menuitem-as"
    }}>{`MenuItem as`}</h5>
    <p><inlineCode parentName="p">{`as?: keyof JSX.IntrinsicElements | React.ComponentType`}</inlineCode></p>
    <p>{`A string representing an HTML element or a React component that will tell the `}<inlineCode parentName="p">{`MenuItem`}</inlineCode>{` what underlying element to render. Defaults to `}<inlineCode parentName="p">{`div`}</inlineCode>{`.`}</p>
    <p>{`You can pass another element or use this prop to render a styled component.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`const StyledItem = styled.div\`
  &[data-selected] {
    background: palevioletred;
  }
\`;

function Example() {
  return (
    <Menu>
      <MenuButton id="example-button">
        Actions <span aria-hidden="true">▾</span>
      </MenuButton>
      <MenuList>
        <MenuItem as={StyledItem} onSelect={action("Download")}>
          Download
        </MenuItem>
        <MenuItem as={StyledItem} onSelect={action("Copy")}>
          Create a Copy
        </MenuItem>
        <MenuItem as={StyledItem} onSelect={action("Mark as Draft")}>
          Mark as Draft
        </MenuItem>
        <MenuItem as={StyledItem} onSelect={action("Delete")}>
          Delete
        </MenuItem>
      </MenuList>
    </Menu>
  );
}
`}</code></pre>
    <h5 {...{
      "id": "menuitem-children"
    }}>{`MenuItem children`}</h5>
    <p><inlineCode parentName="p">{`children: React.ReactNode`}</inlineCode></p>
    <p>{`You can put any type of content inside of a `}<inlineCode parentName="p">{`<MenuItem>`}</inlineCode>{`.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`// jsx-demo
function Example(props) {
  return (
    <Menu>
      <MenuButton>
        Your Cats <span aria-hidden>▾</span>
      </MenuButton>
      <MenuList className="kittys">
        <MenuItem onSelect={() => {}}>
          <img
            src="https://placekitten.com/100/100"
            alt="Fluffybuns the destroyer"
          />
          <span>Fluffybuns the Destroyer</span>
        </MenuItem>
        <MenuItem onSelect={() => {}}>
          <img src="https://placekitten.com/120/120" alt="Simon the pensive" />
          <span>Simon the pensive</span>
        </MenuItem>
      </MenuList>
    </Menu>
  );
}
`}</code></pre>
    <h5 {...{
      "id": "menuitem-disabled"
    }}>{`MenuItem disabled`}</h5>
    <p><inlineCode parentName="p">{`disabled?: boolean`}</inlineCode></p>
    <p>{`Whether or not the item is disabled from selection and navigation.`}</p>
    <h5 {...{
      "id": "menuitem-onselect"
    }}>{`MenuItem onSelect`}</h5>
    <p><inlineCode parentName="p">{`onSelect(): void`}</inlineCode></p>
    <p>{`Callback that fires when a `}<inlineCode parentName="p">{`MenuItem`}</inlineCode>{` is selected.`}</p>
    <h3 {...{
      "id": "menulink"
    }}>{`MenuLink`}</h3>
    <p>{`Handles linking to a different page in the menu. By default it renders `}<inlineCode parentName="p">{`<a>`}</inlineCode>{`, but also accepts any other kind of Link as long as the `}<inlineCode parentName="p">{`Link`}</inlineCode>{` uses the `}<inlineCode parentName="p">{`React.forwardRef`}</inlineCode>{` API.`}</p>
    <p>{`Must be a direct child of a `}<inlineCode parentName="p">{`<MenuList>`}</inlineCode>{`.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`import { Link } from "@reach/router";
<MenuList>
  <MenuLink as={Link} to="somewhere/else">
    Somewhere w/ Reach Router
  </MenuLink>
  <MenuLink href="https://reactjs.org">Official React Site</MenuLink>
  <MenuLink as={GatsbyLink} to="/somewhere/with/gatsby">
    Some Gatsby Page
  </MenuLink>
</MenuList>;
`}</code></pre>
    <h4 {...{
      "id": "menulink-css-selectors"
    }}>{`MenuLink CSS Selectors`}</h4>
    <p>{`Please see the `}<a parentName="p" {...{
        "href": "/styling"
      }}>{`styling guide`}</a>{`.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-css"
      }}>{`[data-reach-menu-item] {
  padding: 20px 10px;
}
`}</code></pre>
    <p>{`To change the styles of a highlighted menu item, use this pseudo-pseudo selector:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-css"
      }}>{`[data-reach-menu-item][data-selected] {
  background: red;
}
`}</code></pre>
    <h4 {...{
      "id": "menulink-props"
    }}>{`MenuLink Props`}</h4>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Prop`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Type`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Required`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "#menulink-element-props"
            }}>{`element props`}</a></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "#menulink-as"
            }}><inlineCode parentName="a">{`as`}</inlineCode></a></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`string`}</inlineCode>{` `}<Pipe mdxType="Pipe" />{` `}<inlineCode parentName="td">{`Component`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`false`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "#menulink-children"
            }}><inlineCode parentName="a">{`children`}</inlineCode></a></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`node`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`false`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "#menulink-disabled"
            }}><inlineCode parentName="a">{`disabled`}</inlineCode></a></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`boolean`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`false`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "#menulink-onselect"
            }}><inlineCode parentName="a">{`onSelect`}</inlineCode></a></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`func`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`false`}</td>
        </tr>
      </tbody>
    </table>
    <h5 {...{
      "id": "menulink-element-props"
    }}>{`MenuLink element props`}</h5>
    <p>{`All props are spread to the underlying element`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`// the \`to\` prop is spread onto the Reach Router Link
<MenuLink as={Link} to="somewhere/else">
  Somewhere
</MenuLink>

// the \`href\` prop is spread onto the underlying \`a\`
<MenuLink href="https://reactjs.org">Official React Site</MenuLink>
`}</code></pre>
    <h5 {...{
      "id": "menulink-as"
    }}>{`MenuLink as`}</h5>
    <p><inlineCode parentName="p">{`as?: keyof JSX.IntrinsicElements | React.ComponentType`}</inlineCode></p>
    <p>{`A string representing an HTML element or a React component that will tell the `}<inlineCode parentName="p">{`MenuLink`}</inlineCode>{` what underlying element to render. Defaults to `}<inlineCode parentName="p">{`a`}</inlineCode>{`.`}</p>
    <p>{`While `}<inlineCode parentName="p">{`MenuLink`}</inlineCode>{` should always render an HTML anchor tag, this is useful to pass a styled component or if you are using a router and need to use its `}<inlineCode parentName="p">{`Link`}</inlineCode>{` component.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`import { Link } from "react-router";
<Menu>
  <MenuButton>Products</MenuButton>
  <MenuList>
    <MenuLink as={Link} to="/settings">
      Settings
    </MenuLink>
    <MenuLink href="https://reacttraining.com/workshops">Workshops</MenuLink>
    <MenuLink href="https://reacttraining.com/courses">Online Courses</MenuLink>
  </MenuList>
</Menu>;
`}</code></pre>
    <p>{`Additionally, if other routers' `}<inlineCode parentName="p">{`Link`}</inlineCode>{` component uses the `}<inlineCode parentName="p">{`React.forwardRef`}</inlineCode>{` API, you can pass them in as well. If they don’t it won't work because we will not be able to manage focus on the element the component renders.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`import { Link } from "gatsby";
<MenuLink as={GatsbyLink} to="/somewhere" />;
`}</code></pre>
    <h5 {...{
      "id": "menulink-children"
    }}>{`MenuLink children`}</h5>
    <p><inlineCode parentName="p">{`children: React.ReactNode`}</inlineCode></p>
    <p>{`You can render any kind of content inside of a MenuLink.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`<MenuLink>
  <ProfileImage userId="4" />
  <UserName>Ryan Florence</UserName>
</MenuLink>
`}</code></pre>
    <h5 {...{
      "id": "menulink-disabled"
    }}>{`MenuLink disabled`}</h5>
    <p><inlineCode parentName="p">{`disabled?: boolean`}</inlineCode></p>
    <p>{`Whether or not the link is disabled from selection and navigation.`}</p>
    <h5 {...{
      "id": "menulink-onselect"
    }}>{`MenuLink onSelect`}</h5>
    <p><inlineCode parentName="p">{`onSelect?(): void`}</inlineCode></p>
    <p>{`Callback that fires when a `}<inlineCode parentName="p">{`MenuLink`}</inlineCode>{` is selected.`}</p>
    <h3 {...{
      "id": "usemenubuttoncontext"
    }}>{`useMenuButtonContext`}</h3>
    <p><inlineCode parentName="p">{`function useMenuButtonContext(): { isExpanded: boolean }`}</inlineCode></p>
    <p>{`A hook that exposes data for a given `}<inlineCode parentName="p">{`Menu`}</inlineCode>{` component to its descendants.`}</p>
    <h2 {...{
      "id": "notes"
    }}>{`Notes`}</h2>
    <h3 {...{
      "id": "unmounting-the-menu-after-an-action"
    }}>{`Unmounting the Menu after an action`}</h3>
    <p>{`If one of your menu items causes the `}<inlineCode parentName="p">{`<Menu>`}</inlineCode>{` itself to unmount, it is your job to move focus to the changed content. One exception to this is if you're using `}<inlineCode parentName="p">{`MenuLink`}</inlineCode>{` and Reach Router. In this case, the router will handle focus for you.`}</p>
    <p>{`Note the callbacks given to `}<inlineCode parentName="p">{`setState`}</inlineCode>{` in the following demo app where focus is managed between screens. If you don't do this you'll drop keyboard and screen reader users off at the top of the document. It'll then be hard for them to know what changed and how to find it. Moving focus helps them stay where you want them the very same way visual design does.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`function Example(props) {
  const screen1FocusRef = React.useRef();
  const screen2ButtonFocusRef = React.useRef();
  React.useEffect(() => {
    requestAnimationFrame(() => {
      if (screen === 1) {
        screen1FocusRef.current.focus();
      }
      if (screen === 2) {
        screen2ButtonFocusRef.current.focus();
      }
    });
  }, [screen]);

  const [screen, setScreen] = React.useState(1);
  if (screen === 1) {
    return (
      <div ref={screen1FocusRef} tabIndex="-1">
        <h4>Screen One</h4>
        <Menu>
          <MenuButton>Actions</MenuButton>
          <MenuList>
            <MenuItem onSelect={() => setScreen(2)}>Go to screen 2</MenuItem>
            <MenuItem onSelect={() => {}}>Do nothing</MenuItem>
          </MenuList>
        </Menu>
        <Menu />
      </div>
    );
  }
  if (screen === 2) {
    return (
      <div>
        <h4>Screen 2</h4>
        <button ref={screen2ButtonFocusRef} onClick={() => setScreen(1)}>
          Back to screen 1
        </button>
      </div>
    );
  }
  return null;
}
`}</code></pre>
    <h3 {...{
      "id": "icons"
    }}>{`Icons`}</h3>
    <p>{`If you add an icon to indicate to users the button is a dropdown menu, use `}<inlineCode parentName="p">{`aria-hidden`}</inlineCode>{` on the icon. Screen readers will already announce to the user that the element is a dropdown menu; adding a label to your icon would be redundant.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`<MenuButton>
  Actions <span aria-hidden>▾</span>
</MenuButton>
`}</code></pre>
    <p>{`However, if you have no text and only an icon, please make sure your icon has a screen reader friendly label:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`// we'd rather it said "Actions" than
// "downward pointing triangle"
<MenuButton>
  <span aria-label="Actions">▾</span>
</MenuButton>

// add screen reader only text for svgs
import VisuallyHidden from "@reach/visually-hidden"
<MenuButton>
  <VisuallyHidden>Actions</VisuallyHidden>
  <svg aria-hidden>
    <polygon points="0,0 20,0 10,10 " />
  </svg>
</MenuButton>

// and your images an alt attribute
<MenuButton>
  <img src="gear.png" alt="gear"/>
</MenuButton>

// Or just label the button and hide everything
<MenuButton aria-label="Actions">
  <span aria-hidden>
    <TripleDots/>
  </span>
</MenuButton>
`}</code></pre>
    <h3 {...{
      "id": "events"
    }}>{`Events`}</h3>
    <p>{`You may want to pass your own click handler to a MenuButton, MenuItem or MenuLink that, in some cases, intercepts and prevents the default behavior from ocurring. You may think to do this by passing a handler to the `}<inlineCode parentName="p">{`onClick`}</inlineCode>{` prop:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`<Menu>
  <MenuButton>Actions</MenuButton>
  <MenuList>
    <MenuItem
      onClick={(event) => {
        if (!userLoggedIn) {
          event.preventDefault();
          openDialog();
        }
      }}
      onSelect={() => alert("Download")}
    >
      Download
    </MenuItem>
  </MenuList>
</Menu>
`}</code></pre>
    <p>{`This won't work because we actually do not call the `}<inlineCode parentName="p">{`onClick`}</inlineCode>{` handler to activate `}<inlineCode parentName="p">{`Menu`}</inlineCode>{` or select `}<inlineCode parentName="p">{`MenuItem`}</inlineCode>{` or `}<inlineCode parentName="p">{`MenuLink`}</inlineCode>{` components. This is because a user may mouse down on a menu button, drag over a menu item and then select it by releasing the mouse trigger. A user may also start clicking one item, then drag to another item before `}<inlineCode parentName="p">{`mouseup`}</inlineCode>{` to change their selectiion.`}</p>
    <p>{`As such, events for each component look more like this:`}</p>
    <ul>
      <li parentName="ul"><inlineCode parentName="li">{`MenuButton`}</inlineCode>{`: Activates `}<inlineCode parentName="li">{`Menu`}</inlineCode>{` in `}<inlineCode parentName="li">{`onMouseDown`}</inlineCode>{` or `}<inlineCode parentName="li">{`onKeyDown`}</inlineCode>{` (`}<kbd>{`Enter`}</kbd>{` or `}<kbd>{`Spacebar`}</kbd>{` keys)`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`MenuItem`}</inlineCode>{`: Selects itself in `}<inlineCode parentName="li">{`onMouseUp`}</inlineCode>{` or `}<inlineCode parentName="li">{`onKeyDown`}</inlineCode>{` (`}<kbd>{`Enter`}</kbd>{` or `}<kbd>{`Spacebar`}</kbd>{` keys)`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`MenuLink`}</inlineCode>{`: Selects itself in `}<inlineCode parentName="li">{`onMouseUp`}</inlineCode>{` or `}<inlineCode parentName="li">{`onKeyDown`}</inlineCode>{` (`}<kbd>{`Enter`}</kbd>{` or `}<kbd>{`Spacebar`}</kbd>{` keys).`}
        <ul parentName="li">
          <li parentName="ul">{`For `}<inlineCode parentName="li">{`MenuLink`}</inlineCode>{`, the `}<inlineCode parentName="li">{`click`}</inlineCode>{` event is fired after the selection events. So if you only need to intercept the event that triggers the anchor link, you can still use `}<inlineCode parentName="li">{`onClick`}</inlineCode>{`, but the rest of the event handlers called in `}<inlineCode parentName="li">{`MenuLink`}</inlineCode>{` will still be which means the `}<inlineCode parentName="li">{`Menu`}</inlineCode>{` will close and your `}<inlineCode parentName="li">{`onSelect`}</inlineCode>{` handler will be triggered.`}</li>
        </ul>
      </li>
    </ul>
    <h3 {...{
      "id": "keyboard-accessibility"
    }}>{`Keyboard Accessibility`}</h3>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Key`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Action`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><kbd>{`Enter`}</kbd></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Open/close while focused on the `}<inlineCode parentName="td">{`MenuButton`}</inlineCode>{`; Selects an item when navigating.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><kbd>{`Spacebar`}</kbd></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Open/close while focused on the `}<inlineCode parentName="td">{`MenuButton`}</inlineCode>{`; Selects an item when navigating.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><kbd>{`ArrowUp`}</kbd></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Highlight previous item`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><kbd>{`ArrowDown`}</kbd></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Highlight next item`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><kbd>{`Home`}</kbd></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Highlight first item`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><kbd>{`End`}</kbd></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Highlight last item`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><kbd>{`Escape`}</kbd></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Close menu`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><kbd>{`Tab`}</kbd></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`No effect`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Type characters`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Highlights matching item`}</td>
        </tr>
      </tbody>
    </table>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      